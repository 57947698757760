import 'toastify-js/src/toastify.css';
import Toastify from 'toastify-js';

function message(text, bgColor, timeout) {
    return {
        text: text,
        duration: timeout,
        close: true,
        gravity: 'top',
        position: 'right',
        style: {
            background: bgColor
        },
        showHideTransition: 'fade'
    };
}

$.extend({
    notify: {
        success: function (text) {
            return Toastify(message(text, '#3f9f3f', 2000)).showToast();
        },
        warning: function (text) {
            return Toastify(message(text, '#f6b600', 5000)).showToast();
        },
        error: function (text) {
            return Toastify(message(text, '#f62d51', 5000)).showToast();
        }
    }
});