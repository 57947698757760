import 'jquery-validation/dist/jquery.validate';
import 'jquery-validation-unobtrusive/dist/jquery.validate.unobtrusive';

$(function () {
  $('.validation-form').each(function () {
    $(this).validate({
      errorClass: 'text-danger',
      highlight: function (element, errorClass) {
        var jqElement = $(element);
        jqElement.attr('aria-invalid', true);
        jqElement.closest('.form-group').addClass('error');
      },
      unhighlight: function (element, errorClass, validClass) {
        var jqElement = $(element);
        jqElement.attr('aria-invalid', false);
        jqElement.closest('.form-group').removeClass('error');
        jqElement.closest('.controls').find('span').text('');
      }
    });
  });

  $.validator.messages.required = 'This field is required';

  $.validator.addMethod('lessThan', function (value, element, param) {
    return parseInt($(element).val()) < parseInt($(param).val());
  });

  $.validator.addMethod('lessOrEqualThan', function (value, element, param) {
    return parseInt($(element).val()) <= parseInt($(param).val());
  });

  $.validator.addMethod('greaterOrEqualThan', function(value, element, param) {
    return parseInt($(element).val()) >= parseInt($(param).val());
  });

  $.validator.addMethod('equalTo', function (value, element, param) {
    return $(element).val() === $(param).val();
  });

  $.validator.addMethod('dateLessOrEqualThan', function (value, element, param) {
    var elementVal = $(element).val();
    var paramVal = $(param).val();

    return moment(elementVal).isBefore(moment(paramVal)) || elementVal === paramVal;
  });

  $.validator.addMethod('requiredIfChecked', function (value, element, param) {
    var isChecked = $(param).is(':checked');
    var elementVal = $(element).val();
    return !isChecked || elementVal;
  });

  $.validator.addMethod(
      "regex",
      function(value, element, regexp) {
        var re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
      }
  );

    $.validator.addMethod('atLeastOneChecked', function(value, element, nameParams) {
      nameParams = ("" + nameParams).split(',');
      for (var i = 0; i < nameParams.length; i++) {
        var nodeName = "[name='" + nameParams[i] + "']";
        if ($(nodeName).is(':checked')) {
              return true;
            }
        }

      return false;
    });
});