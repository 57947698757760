import './index.scss';
import 'bootstrap';
import '~/libs/monster-admin/index';
import 'jquery-slimscroll/jquery.slimscroll';
import 'node-waves/dist/waves';
import 'sticky-kit/dist/sticky-kit';
import 'chartist/dist/chartist';
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip';
import '~/app/components/toast';
import '~/app/components/jquery-validation/index';

$(function () {
  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });
  document.cookie = "timeOffset=" + new Date().getTimezoneOffset();
  $.fn.modal.Constructor.prototype._enforceFocus = function() {};
  $('#logout-action').click(function () {
    $.ajax({
      url: API.Home.Logout(),
      type: 'POST'
    }).done(function () {
      window.location.href = API.Home.Login();
    });
  });

  var $testModal = $('.change-password-modal');

  let changePasswordModalVue = new Vue({
    el: '#ChangePasswordModalVue',
    data: {
      isLoading: false,
      model: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined,
      }
    },
    methods: {
      saveData() {
        let self = this;
        self.isLoading = true;
        var x = $('#change-password-form').valid();
        if(!x){
          return true;
        }
        $.ajax({
          url: API.Home.ChangePassword(),
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(self.model)
        }).done(function () {
          self.isLoading = false;
          $.notify.success('Password successfully updated.');
          self.closeModal();
        }).fail(function (error) {
          self.isLoading = false;
          $.notify.error('Password update error: ' + error.responseJSON.join(', '));
        });
      },
      closeModal() {
        $testModal.modal("hide");
      },
    }
  });

  $('#change-password-action').click(function () {
    $testModal.modal('show');
    $('#change-password-form').validate();
  });
});
